

$(document).ready(function () {


    $(".scroll-to").click(function(){
        var target = $(this).attr("href");
        $('html, body').animate({
            scrollTop: $(target).offset().top
        }, 1000);
    })

    $("#mobile-menu").mmenu({
    });

    $("#enquiry-form input[type='submit']").click(function(e){
        var data = $("#enquiry-form").serialize();

        // Validation
        if ($("#enquiry-form input[name='name']").val()=="") {
            swal("Oops...","You forgot to write down your name.","warning");
            e.preventDefault();
            return;
        }
        if ($("#enquiry-form input[name='contactNumber']").val()==""&&$("#enquiry-form input[name='email']").val()=="") {
            swal("Oops...","You must leave at least one contact info so that we can get back to you.","warning");
            e.preventDefault();
            return;
        }
        if (!$("#enquiry-form select[name='enquiryType']").val()||$("#enquiry-form textarea[name='content']").val()=="") {
            swal("Oops...","You must complete both enquiry type and details.","warning");
            e.preventDefault();
            return;
        }

        $.ajax({
            url: 'email.php',
            type: 'POST',
            data: data,
        })
        .done(function(data) {
            if (data=="1") {
                swal("Congratulations!","Your enquiry has been successfully submitted", 'success');
            }
        })
        .fail(function() {
            swal("Oops...","There is something wrong when we submit your request. Please try again or send the enquiry to enquiries@australiancampservices.com.au", 'error');
        });
        
        e.preventDefault();
    });

    $('.carousel-slides').slick({
      dots: false,
      infinite: true,
      speed: 500,
      fade: true,
      autoplay: true,
      arrows: false
    }); 

});





